
import { computed, defineComponent, ref, watch } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useStore } from "vuex";
import { ScheduleActions, ScheduleGetters } from "@/store/vm/schedule/enums";
import { StepResult } from "@/store/captain/CaptainModule";
import TeacherSelector from "@/views/people/teacher/selector/Teacher.vue";
import { Schedule } from "@/store/vm/schedule/ScheduleModule";
import { convertDateToHHmm } from "@/core/helpers/common";

export default defineComponent({
  name: "upsert-schedule",
  components: {
    TeacherSelector,
  },
  props: {
    scheduleId: {
      type: [Number, Boolean],
      required: true,
      default: false,
    },
  },
  emits: ["reset"],
  setup(props, { emit }) {
    const store = useStore();

    const formRef = ref<null | HTMLFormElement>(null);
    const scheduleUpsertModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);

    const valueTimeRangePicker = ref([
      new Date(2022, 1, 1, 18, 0),
      new Date(2022, 1, 1, 20, 0),
    ]);

    const defaultData = {
      id: 0,
      lesson_name: "",
      zoom_meeting_id: "",
      zoom_meeting_pwd: "",
      teacher_id: [0],
      date: "",
      start_time: "",
      end_time: "",
      class_id: 0,
    };

    const formData = ref({ ...defaultData });

    const modalUpsertId = computed(() => {
      return store.state.ScheduleModule.modalUpsertId;
    });

    const rules = ref({});

    const isCreate = computed(() => {
      return !props.scheduleId;
    });

    const scheduleIdTarget = computed(() => {
      return props.scheduleId;
    });

    watch(scheduleIdTarget, (currentValue) => {
      if (currentValue) {
        const detail: Schedule =
          store.getters[ScheduleGetters.GET_BY_ID](currentValue);

        const targetDate = new Date(detail.date);
        targetDate.setHours(targetDate.getHours() + 7);

        formData.value = {
          id: detail.id,
          class_id: detail.class_id,
          lesson_name: detail.lesson_name,
          teacher_id: [detail.teacher_id],
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          date: targetDate.toISOString().substr(0, 10) as any,
          start_time: "",
          end_time: "",
          zoom_meeting_id: detail?.meetingLive?.["meetingId"] || "",
          zoom_meeting_pwd: detail?.meetingLive?.["pwd"] || "",
        };

        const startHour = parseInt(detail.start_time.substr(0, 2));
        const startMin = parseInt(detail.start_time.substr(3, 2));

        const endHour = parseInt(detail.end_time.substr(0, 2));
        const endMin = parseInt(detail.end_time.substr(3, 2));

        valueTimeRangePicker.value = [
          new Date(2022, 1, 1, startHour, startMin),
          new Date(2022, 1, 1, endHour, endMin),
        ];
      } else {
        reset();
      }
    });

    const reset = () => {
      formData.value = defaultData;
      emit("reset");
    };

    const actionCreate = (par) => {
      return store.dispatch(ScheduleActions.CREATE, par).catch((e) => {
        console.log(e);
      });
    };

    const actionUpdate = async (par) => {
      return await store.dispatch(ScheduleActions.UPDATE, par).catch((e) => {
        console.log(e);
      });
    };

    const submit = async () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          loading.value = true;
          let stepResult: StepResult;

          const valueGot = valueTimeRangePicker.value;
          // eslint-disable-next-line
          const startDate: Date = valueGot[0] as any;
          // eslint-disable-next-line
          const endDate: Date = valueGot[1] as any;
          formData.value.start_time = convertDateToHHmm(startDate);
          formData.value.end_time = convertDateToHHmm(endDate);

          const data = { ...formData.value };
          if (props.scheduleId) {
            stepResult = await actionUpdate(data);
          } else {
            stepResult = await actionCreate(data);
          }

          loading.value = false;
          if (stepResult.isSuccess) {
            hideModal(scheduleUpsertModalRef.value);
            Swal.fire({
              position: "top-end",
              text: "Success!",
              icon: "success",
              buttonsStyling: false,
              timer: 2000,
              width: 400,
              showCancelButton: false,
              showConfirmButton: false,
            });
            reset();
          } else {
            Swal.fire({
              position: "top-end",
              text: "Đã xảy ra lỗi, vui lòng thử lại sau!",
              icon: "error",
              buttonsStyling: false,
              timer: 2000,
              width: 400,
              showCancelButton: false,
              showConfirmButton: false,
            });
          }
        } else {
          Swal.fire({
            text: "Định dạng dữ liệu không đúng, vui lòng kiểm tra lại biểu mẫu",
            icon: "error",
            buttonsStyling: false,
            timer: 2000,
            width: 400,
            showCancelButton: false,
            showConfirmButton: false,
          });
          return false;
        }
      });
    };

    const onChange = (payload) => {
      const name = payload.name;
      const value = payload.value;
      formData.value[name] = value;
    };

    return {
      valueTimeRangePicker,
      onChange,
      modalUpsertId,
      isCreate,
      defaultData,
      formData,
      submit,
      formRef,
      rules,
      loading,
      scheduleUpsertModalRef,
      scheduleIdTarget,
    };
  },
});
