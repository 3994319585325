
import { defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { ClassActions } from "@/store/vm/class/enums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { StepResult } from "@/store/captain/CaptainModule";
import {
  convertDateToDDMMYYYGMT7,
  exportJsonToCsv,
} from "@/core/helpers/common";

export default defineComponent({
  name: "report-attendance",
  components: {},
  props: {
    classId: Number,
  },
  setup(props) {
    const store = useStore();

    const students = ref([]);
    const schedules = ref([]);

    onMounted(async () => {
      await apply();
    });

    const apply = async () => {
      const result: StepResult = await store
        .dispatch(ClassActions.GET_REPORT_ATTENDANCE, props.classId)
        .catch((e) => {
          console.log(e);
        });

      if (result.isSuccess) {
        students.value = (result.data as any).attendance;
        schedules.value = (result.data as any).schedules;
      } else {
        console.error("Xảy ra lỗi trong quá trình lấy báo cáo điểm danh");
        console.log(result);
      }
    };

    const getSummaryAttend = (attendance: boolean[]) => {
      let numberFalse = 0;
      attendance.map((m) => {
        if (!m) {
          numberFalse++;
        }
      });
      return `${numberFalse}/${attendance.length}`;
    };

    const clickDownloadCSV = async () => {
      const result: StepResult = await store
        .dispatch(ClassActions.GET_JSON_DATA_REPORT_ATTENDANCE, props.classId)
        .catch((e) => {
          console.log(e);
        });

      if (result.isSuccess) {
        const headers = (result.data as any).headers;
        const records = (result.data as any).records;
        exportJsonToCsv(headers, records, "Báo cáo điểm danh.csv");

        Swal.fire({
          icon: "success",
          title: "Download thành công",
          text: "Nếu bạn gặp vấn đề về hiển thị font tiếng việt với file *.CSV, vui lòng xem hướng dẫn dưới đây !",
          footer:
            '<a href="https://fate-option-40b.notion.site/H-ng-d-n-m-file-csv-ti-ng-Vi-t-7b2957d5cbf34bc18e16949587b175d2" target="_blank">Hướng dẫn </a>',
        });
      } else {
        console.error(
          "Xảy ra lỗi trong quá trình lấy báo cáo điểm danh dạng JSON"
        );
        console.log(result);
      }
    };

    return {
      students,
      schedules,
      apply,
      getSummaryAttend,
      convertDateToDDMMYYYGMT7,
      clickDownloadCSV,
    };
  },
});
