
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import List from "@/views/schedule/List.vue";
import Upsert from "@/views/schedule/Upsert.vue";

import { defineComponent, onMounted } from "vue";
import { MenuComponent } from "@/assets/ts/components";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useStore } from "vuex";
import { ScheduleActions, ScheduleGetters } from "@/store/vm/schedule/enums";

interface ComponentData {
  scheduleIdModal: number | boolean;
}
export default defineComponent({
  name: "schedule",
  props: {
    classId: Number,
  },
  components: {
    List,
    Upsert,
  },
  data(): ComponentData {
    return {
      scheduleIdModal: false,
    };
  },
  methods: {
    onUpsert(scheduleId: number | boolean) {
      console.log("on upsert");
      console.log(scheduleId);
      this.scheduleIdModal = scheduleId;
    },
    onReset() {
      this.scheduleIdModal = false;
    },
  },
  setup(props) {
    const store = useStore();

    onMounted(() => {
      setCurrentPageBreadcrumbs("Thương hiệu", ["Hệ thống"]);
      MenuComponent.reinitialization();
    });

    const onDel = (scheduleId) => {
      Swal.fire({
        title: "Bạn muốn xóa lịch học này?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const resDel = await actionDelete(scheduleId);
          console.log("resDel");
          console.log(resDel);
        }
      });
    };

    const actionDelete = async (scheduleId) => {
      return store
        .dispatch(ScheduleActions.DELETE, {
          classId: props.classId,
          scheduleId,
        })
        .catch((e) => {
          console.log(e);
        });
    };

    return {
      onDel,
    };
  },
});
