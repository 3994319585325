
import { ScheduleActions } from "@/store/vm/schedule/enums";
import { computed, defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { StepResult } from "@/store/captain/CaptainModule";
import moment from "moment";
import { convertDateToWeekday } from "@/core/helpers/common";

interface CreateZoomMeetingResponse {
  day_before_create_meeting: number;
  waiting: number;
  success: number;
  fail: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fail_detail: any[];
}

export default defineComponent({
  name: "schedule-list",
  components: {},
  props: {
    classId: Number,
  },
  emits: ["upsert", "del"],
  data() {
    return {
      loadingModule: true,
    };
  },
  setup(props, { emit }) {
    const store = useStore();
    const checked = ref(false);
    const { t } = useI18n();
    const isLoadingGenerateSchedule = ref(false);
    const isLoadingGenerateZoomMeeting = ref(false);
    const dialogVisible = ref(false);

    const classId = computed(() => {
      return props.classId;
    });

    onMounted(() => {
      store
        .dispatch(ScheduleActions.GET_ALL_SCHEDULE_BY_CLASS_ID, classId.value)
        .catch((e) => {
          console.log(e);
        });
    });

    const list = computed(() => {
      const listInStore = store.state.ScheduleModule.list;
      listInStore.map((m) => {
        if (m.meeting) {
          m.meetingLive = JSON.parse(m.meeting);
        }
      });
      return store.state.ScheduleModule.list;
    });

    const count = computed(() => {
      return store.state.ScheduleModule.list.length;
    });

    const upsertSchedule = (scheduleId) => {
      console.log("upsert emit");
      emit("upsert", scheduleId);
    };

    const delSchedule = (scheduleId) => {
      emit("del", scheduleId);
    };

    const clickGenerateSchedule = async () => {
      dialogVisible.value = false;

      isLoadingGenerateSchedule.value = true;
      const stepResult: StepResult = await store
        .dispatch(ScheduleActions.GENERATE_SCHEDULE, classId.value)
        .catch((e) => {
          console.log(e);
        });
      isLoadingGenerateSchedule.value = false;

      if (stepResult.isSuccess) {
        Swal.fire({
          position: "top-end",
          text: "Success!",
          icon: "success",
          buttonsStyling: false,
          timer: 2000,
          width: 400,
          showCancelButton: false,
          showConfirmButton: false,
        });
      } else {
        Swal.fire({
          position: "top-end",
          text: "Đã xảy ra lỗi, vui lòng thử lại sau!",
          icon: "error",
          buttonsStyling: false,
          timer: 2000,
          width: 400,
          showCancelButton: false,
          showConfirmButton: false,
        });
      }
    };

    const generateZoomMeeting = async () => {
      isLoadingGenerateZoomMeeting.value = true;
      const stepResult: StepResult = await store
        .dispatch(ScheduleActions.GENERATE_ZOOM_MEETING, classId.value)
        .catch((e) => {
          console.log(e);
        });
      isLoadingGenerateZoomMeeting.value = false;

      if (stepResult.isSuccess) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const response: CreateZoomMeetingResponse = stepResult.data as any;

        let errorText = "";
        if (response.fail > 0) {
          errorText += `Số meeting tạo thất bại: ${response.fail} <br> Nguyên nhân: <br>`;

          response.fail_detail.map((m) => {
            errorText += m.reason + "<br>";
          });
        }

        Swal.fire(
          `Đã tạo thành công ${response.success}/${response.waiting} meeting cho ${response.day_before_create_meeting} ngày tiếp theo`,
          errorText,
          errorText ? "error" : "success"
        );
      } else {
        Swal.fire({
          position: "top-end",
          text: "Đã xảy ra lỗi, vui lòng thử lại sau!",
          icon: "error",
          buttonsStyling: false,
          timer: 2000,
          width: 400,
          showCancelButton: false,
          showConfirmButton: false,
        });
      }
    };

    const statusLabels = {
      0: "Dự kiến",
      1: "Chính thức",
      10: "Đã học",
      11: "Đã hủy",
    };

    const typeLabels = {
      0: "Online",
      1: "Offline",
    };

    const modalUpsertId = store.state.ScheduleModule.modalUpsertId;

    const lang = {
      edit: t("common.edit"),
      delete: t("common.delete"),
    };

    return {
      list,
      checked,
      count,
      upsertSchedule,
      delSchedule,
      modalUpsertId,
      lang,
      clickGenerateSchedule,
      generateZoomMeeting,
      moment,
      statusLabels,
      typeLabels,
      convertDateToWeekday,
      isLoadingGenerateSchedule,
      isLoadingGenerateZoomMeeting,
      dialogVisible,
    };
  },
});
