
import { TeacherActions } from "@/store/vm/people/teacher/enums";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";

interface OptionSelect {
  label: string;
  value: string | number;
}

export default defineComponent({
  name: "teacher-selector",
  emits: ["change"],
  props: {
    name: {
      type: String,
      default: "teacherId",
    },
    value: {
      type: Array,
      default: () => [],
    },
    allowMultiple: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    teacherIdTarget: {
      get() {
        return this.value;
      },
      set(modelValue) {
        this.$emit("change", {
          name: this.$props.name,
          value: modelValue,
        });
      },
    },
  },
  setup() {
    const store = useStore();

    onMounted(() => {
      store.dispatch(TeacherActions.GET_LIST_TEACHER).catch((e) => {
        console.log(e);
      });
    });
    const options = ref<OptionSelect[]>([]);
    const list = computed(() => {
      return store.state.TeacherModule.list;
    });

    watch(list, (currentValue) => {
      if (currentValue) {
        const opts: OptionSelect[] = [];
        currentValue.map((m) => {
          opts.push({
            value: m.id,
            label: m.first_name + " " + m.last_name + " (" + m.email + ")",
          });
        });
        options.value = opts;
      }
    });

    return {
      list,
      options,
    };
  },
});
