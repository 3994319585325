
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { defineComponent, onMounted } from "vue";
import { MenuComponent } from "@/assets/ts/components";
import IndexSchedule from "@/views/schedule/Index.vue";
import IndexStudent from "@/views/class/student/Index.vue";
import ReportAttendance from "@/views/class/child/ReportAttendance.vue";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "class-compose",
  props: {},
  components: {
    IndexSchedule,
    IndexStudent,
    ReportAttendance,
  },
  setup() {
    const route = useRoute();
    const classId = route.params["classId"];

    onMounted(() => {
      setCurrentPageBreadcrumbs("Lớp học", ["Đào tạo"]);
      MenuComponent.reinitialization();
    });

    return {
      classId,
    };
  },
});
